import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SearchField from './SearchField';
import { BaseGrid, GridCell } from './Grid';
import { Select, Option, FormControl } from './SelectMenus/index';
import Checkbox from './Checkbox';

const StyledBaseGrid = styled(BaseGrid)`
  ${({ maxWidth }) => maxWidth && 'margin: 0 auto;'}
`;

const ProfileListFilters = ({
  searchId,
  searchValue,
  searchLabel,
  searchOnChange,
  searchOnClear,
  countryId,
  countryValue,
  countryOnChange,
  countries,
  allCountriesLabel,
  premiumChecked,
  premiumOnChange,
  extraFilters,
  maxWidth,
}) => (
  <StyledBaseGrid spacing={2} maxWidth={maxWidth}>
    <GridCell lg={countryId ? 8 : 12} padding="16px 0 16px 16px">
      <SearchField
        id={searchId}
        fullWidth
        value={searchValue}
        label={searchLabel}
        onChange={searchOnChange}
        onClear={searchOnClear}
      />
    </GridCell>
    { countryId && (
    <GridCell lg={4} padding="16px 0 16px 16px">
      <FormControl
        variant="outlined"
        fullWidth
      >
        <Select
          id={countryId}
          value={countryValue}
          inputProps={{
            'data-testid': countryId,
          }}
          onChange={countryOnChange}
        >
          {countries.map((countryOption) => (
            <Option
              key={countryOption}
              value={countryOption}
              data-testid={`${countryId}-option`}
            >
              { countryOption || allCountriesLabel }
            </Option>
          ))}
        </Select>
      </FormControl>
    </GridCell>
    )}
    <GridCell padding="0 0 0 16px">
      <Checkbox
        checked={premiumChecked}
        label="Premium"
        onChange={premiumOnChange}
      />
    </GridCell>
    <GridCell padding="0 0 0 16px">
      {extraFilters}
    </GridCell>
  </StyledBaseGrid>
);

ProfileListFilters.propTypes = {
  searchId: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  searchLabel: PropTypes.string.isRequired,
  searchOnChange: PropTypes.func.isRequired,
  searchOnClear: PropTypes.func.isRequired,
  countryId: PropTypes.string,
  countryValue: PropTypes.string,
  countryOnChange: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.string),
  allCountriesLabel: PropTypes.string,
  premiumChecked: PropTypes.bool.isRequired,
  premiumOnChange: PropTypes.func.isRequired,
  extraFilters: PropTypes.node,
  maxWidth: PropTypes.number,
};

ProfileListFilters.defaultProps = {
  searchValue: '',
  extraFilters: undefined,
  countryId: undefined,
  countryValue: undefined,
  countryOnChange: undefined,
  allCountriesLabel: undefined,
  countries: undefined,
  maxWidth: undefined,
};

export default ProfileListFilters;
